export function initScrollRankingPage() {
    const desktopBodyWidth = 1200;
    const $filtersRankingBottomPanel = $('.filters-ranking-bottom-panel');
    $filtersRankingBottomPanel.hide();
    $(window).on('scroll', function () {
        if (screen.width < desktopBodyWidth && $(window).scrollTop() > 230) {
            $filtersRankingBottomPanel.show();
        } else {
            $filtersRankingBottomPanel.hide();
        }
    });

    $(window).on('resize', function () {
        if (screen.width < desktopBodyWidth && $(window).scrollTop() > 230) {
            $filtersRankingBottomPanel.show();
        } else {
            $filtersRankingBottomPanel.hide();
        }
    });

}
