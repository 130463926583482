import {initFiltersListeners} from '@js/modules/public/accreditations/index/filtersHandle';
import {switchTab} from "@js/helpers/tabSwitcher";
import {initScrollRankingPage} from "@js/components/ranking/scrollRankingPage";


$(() => {
    const urlAnchor = location.hash;
    if (urlAnchor !== '') {
        switchTab(urlAnchor);
    }

    $('body')
        .on('shown.bs.tab', 'a[data-toggle="tab"]', (e) => {
            document.location.hash = $(e.target).attr("href");
            window.scrollTo(0, 0);
        });
    initFiltersListeners();
    initScrollRankingPage();
});
