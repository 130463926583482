export function switchTab(anchor) {
    const $nav = $('body').find('.content-header__tabs-wrapper ul');
    const $tabs = $nav.children();
    $tabs.each(function () {
        const link = $(this).find('a');
        const controls = link[0];
        if ($(controls).attr('href') === anchor) {
            $(this).addClass('active');
        } else {
            $(this).removeClass('active');
        }
    });
    const $containers = $('body').find('.tab-pane');
    $containers.each(function () {
        if ($(this).attr('id') === anchor.substring(1)) {
            $(this).addClass('active');
        } else {
            $(this).removeClass('active');
        }
    });
}
