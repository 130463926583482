import {getUrlParameter, reloadWithoutGetParams, replaceUrlParam} from '@js/helpers/location';
import {sendRequest} from '@js/utils';
import {routes} from '@js/helpers/routes';
import '@plugins/jquery.autocomplete/jquery.autocomplete.css';
import '@plugins/jquery.autocomplete/jquery.autocomplete.min';
import {isEqual} from 'lodash';

function getFormParams(isDesktopForm = true) {
    const $modalFiltersForm = $('#modal-filter-form');
    const $filterForm = isDesktopForm ? $('form.ranking-filters').not($modalFiltersForm) : $modalFiltersForm;
    return `${$filterForm.serialize()}`;
}

function refreshAccreditationsPage(isDesktopForm = true) {
    window.location.search = getFormParams(isDesktopForm);
}

export function initFiltersListeners() {
    const $form = $('.sidebar__filters');
    const $multipleNaprCodesSelect = $form.find('[name="napr_codes[]"]');
    $multipleNaprCodesSelect.selectpicker({
        noneSelectedText: 'Все',
        liveSearch: true
    });
    initMultipleSelectFilterListener($multipleNaprCodesSelect);
    const $multipleRegistersSelect = $form.find('[name="registers[]"]');
    $multipleRegistersSelect.selectpicker({
        noneSelectedText: 'Все'
    });
    initMultipleSelectFilterListener($multipleRegistersSelect);
    const $multipleAgenciesSelect = $form.find('[name="agencies[]"]');
    $multipleAgenciesSelect.selectpicker({
        noneSelectedText: 'Выберите агентства',
        liveSearch: true
    });
    initMultipleSelectFilterListener($multipleAgenciesSelect);
    const $multipleAccredTypesSelect = $form.find('[name="accred_types[]"]');
    $multipleAccredTypesSelect.selectpicker({
        noneSelectedText: 'Выберите типы аккредитации',
    });
    initMultipleSelectFilterListener($multipleAccredTypesSelect);
    const $multipleRegionSelect = $form.find('[name="regions[]"]');
    $multipleRegionSelect.selectpicker({
        noneSelectedText: 'Все',
        liveSearch: true
    });
    initMultipleSelectFilterListener($multipleRegionSelect);
    $('#accreditationsFilter')
        .on('change', '[name="criterion[]"], [name="vedom"], [name="agency"]', refreshAccreditationsPage)
        .on('click', '.remove-all-applied-filters', reloadWithoutGetParams);
    $('.applied-filters')
        .on('click', '.remove-all-applied-filters', reloadWithoutGetParams)
        .on('click', '.remove-applied-filter', function () {
            const isMultiple = $(this).data('multiple') === true;
            const filterName = $(this).data('filter');
            let replacementValue;
            if (isMultiple) {
                const multipleFilterValue = $(this).data('multiple-value');
                const urlParamValues = getUrlParameter(filterName);
                replacementValue = urlParamValues.filter((item) => {
                    return item != multipleFilterValue;
                });
            } else {
                replacementValue = '';
            }
            window.location.search = replaceUrlParam(filterName, replacementValue);
        });
    const $modalFiltersForm = $('#modal-filter-form');

    $modalFiltersForm
        .on('click', '#btnFilter', () => {
            refreshAccreditationsPage(false);
        });
    const $autocompleteInput = $('.ranking-filters input[name="organization"]');
    const location = routes.public.accreditations.getOrganizationsList();
    if ($autocompleteInput.length) {
        initAutocomplete($autocompleteInput, location);
    }
}

export function initAutocomplete($autocompleteInput, location) {
    $(window)
        .scroll(() => {
            $autocompleteInput.autocomplete('hide');
        });
    sendRequest('get', location, {}, (res) => {
        if (res?.success) {
            const autocompleteValues = res.list;
            $autocompleteInput.autocomplete({
                minChars: 2,
                width: 500,
                lookup: autocompleteValues,
                triggerSelectOnValidInput: false,
                onSelect() {
                    const $form = $(this).closest('.ranking-filters');
                    if ($form.attr('id') !== 'modal-filter-form') {
                        refreshAccreditationsPage();
                    }
                },
            });
        }
    });
}

function createApplyButtonWrapper() {
    return `<div class="apply-multiple-select">
        <button type="button" class="btn btn-xs btn-default apply-multiple-select__btn">
            Применить фильтр
        </button>
    </div>`;
}

export function initMultipleSelectFilterListener($multipleSelects) {
    if ($multipleSelects.length) {
        const initialSelectedValues = {};
        $multipleSelects.each(function () {
            const $select = $(this);
            const id = $select.attr('id');
            initialSelectedValues[id] = $select.val();
        });
        let isRefreshPageStarted = false;
        const desktopIdName = 'accreditationsFilter';
        $multipleSelects
            .on('hidden.bs.select', function () {
                const $select = $(this);
                const id = $select.attr('id');
                $select.css('vertical-align', 'top');
                if (!isEqual(initialSelectedValues[id], $select.val()) && !isRefreshPageStarted) {
                    const $form = $(this).closest('form');
                    if ($form.attr('id') === desktopIdName) {
                        refreshAccreditationsPage();
                    }
                    isRefreshPageStarted = true;
                }
            })
            .on('changed.bs.select', (e) => {
                const $select = $(e.currentTarget);
                const $selectDropdown = $select.closest('.sidebar__filter')
                    .find('.dropdown-menu.open');
                const selectHeight = parseInt($selectDropdown.css('max-height'), 10);
                const innerHeight = parseInt($selectDropdown.find('.inner.open').css('max-height'), 10);
                if (selectHeight - innerHeight < 45) {
                    $selectDropdown.css('max-height', innerHeight + 45);
                }
            })
            .on('loaded.bs.select', function () {
                const $select = $(this);
                const $selectDropdown = $select.closest('.sidebar__filter')
                    .find('.dropdown-menu.open');
                const $applyButtonWrapper = createApplyButtonWrapper();
                $selectDropdown.prepend($applyButtonWrapper);
                $selectDropdown
                    .on('click', '.apply-multiple-select__btn', function () {
                        const $form = $(this).closest('form');
                        if ($form.attr('id') === desktopIdName) {
                            refreshAccreditationsPage();
                        }
                        isRefreshPageStarted = true;
                    });
            });
    }
}
